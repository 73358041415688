import { initializeApp } from "firebase/app";
import { updateProfile, getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyACRi4hIZ8893M5IBQcypdRoCIXUpQ9A0g",
  authDomain: "tecnigas-28065.firebaseapp.com",
  projectId: "tecnigas-28065",
  storageBucket: "tecnigas-28065.appspot.com",
  messagingSenderId: "321933761201",
  appId: "1:321933761201:web:08984fea522a87a2176090",
  measurementId: "G-1N20FDK6MF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export { app, updateProfile, getAuth, signInWithEmailAndPassword, onAuthStateChanged }