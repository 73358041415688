<template>
    <div>
        <v-app-bar flat height="40" color="#fff" style="color:black" app>
          <span style="font-size:10px;">
              <strong>Tecnigas HP  -  J504789658</strong>
            </span>
          <v-spacer></v-spacer>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" color="black"
                v-on="on" 
                icon text @click="perfilMenu = !perfilMenu"><i class="far fa-sun"></i></v-btn>
            </template>
            <span>Opciones</span>
            </v-tooltip>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" color="black"
                v-on="on" 
                icon text @click="nav = !nav"><i class="fas fa-bars"></i></v-btn>
            </template>
            <span>Desplegar Menu</span>
            </v-tooltip>
        </v-app-bar>

        <v-navigation-drawer v-model="perfilMenu" fixed app left width="200">
          <div style="padding:30px 10px 10px 10px;">
            <span v-if="saludo == 1">🌑</span>
            <span v-if="saludo == 2">🌤️</span>
            <span v-if="saludo == 3">☀️</span>
            <span v-if="saludo == 4">🌇</span>
            <span v-if="saludo == 5">🥱</span>
            <span style="font-size:12px">
              Hola, <strong>{{usuario.username}}</strong>.
            </span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
            icon x-small text @click="signOut()" rounded color="red" style="border:solid 1px red;
        text-transform: none;letter-spacing: 0;font-weight: 600;
        ">
        <i class="fas fa-sign-out-alt"></i>
        </v-btn>
            </template>
            <span>Salir</span>
            </v-tooltip>
          </div>

          <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="selectedItem"
            color="#2379bf49cc"
          >
            <v-list-item
              v-for="(item, i) in itemsPerfil"
              :key="i"
              :to="item.sitio"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        </v-navigation-drawer>



        <v-navigation-drawer v-model="nav" fixed app v-if="usuario != null" right>
            <v-img style="margin:20px auto;display:block;" width="200" src="@/assets/logo.png"></v-img>
            <div style="margin:0 auto;display:block;">


            <v-list dense shaped>
                <v-list-item to="/Dashboard" color="#2379bf49"
                active-class="hash"
                >
                <v-list-item-title style="font-weight:600">Inicio</v-list-item-title>
                </v-list-item>
                <v-list-group color="#2379bf"
                append-icon="fas fa-sort-down"
                    v-for="item in items"
                    :key="item.title"
                    no-action
                    active-class="hash"
                >
                    <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title" style="font-weight:600"></v-list-item-title>
                    </v-list-item-content>
                    </template>

                    <v-list-item
                    v-for="child in item.items"
                    :key="child.title"
                    :to="child.link"
                    >
                    <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>

            </div>
        </v-navigation-drawer>
    </div>
</template>

<style>
.hash{
  border-left: solid 5px #237abf;
}</style>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data(){
        return {
          selectedItem:1,saludo:'',
          closss:'Cerrar Sesión',
          itemsPerfil:[
            {icon: 'far fa-user-circle',
            text: 'Inicio',
            sitio: '/'}
            
          ],
            nav:true,
            perfilMenu:true,
            items: [
        {
          items: [
            { title: 'Crear', link: '/Clientes/Nuevo' },
            { title: 'Todos los clientes', link: '/Clientes/Listado' },
          ],
          title: 'Clientes',
        },
        {
          items: [
            { title: 'Crear / Ver', link: '/Inventario' },
          ],
          title: 'Inventario',
        },
        {
          items: [
            { title: 'Crear', link: '/Presupuesto/Nuevo' },
            { title: 'Todos los Presupuestos', link: '/Presupuesto/Listado' },
          ],
          title: 'Presupuestos',
        }
      ], 
        }
    },
    methods:{
      slaudos(){
        const fecha = new Date(); 
        const hora = fecha.getHours();
        if(hora >= 0 && hora < 6){
          this.saludo = 1;
        }
        if(hora >= 6 && hora < 8){
          this.saludo = 2;
        }
        if(hora >= 8 && hora < 17){
          this.saludo = 3;
        }
        if(hora >= 17 && hora < 19){
          this.saludo = 4;
        }
        if(hora >= 19 && hora < 24){
          this.saludo = 5;
        }
        return this.saludo
      },
        ...mapActions(['signOut']),
        irReporteProducto(a, e){
          if (e == 1) {
            window.open(a+this.usuario.uid, "_blank");
          }else if (e == 2 && this.usuario.rank == 1) {
            window.open(a, "_blank");
          }
        }
    },
    computed:{
        ...mapState(['usuario'])
    },
    mounted(){
      this.slaudos()
    }
}
</script>