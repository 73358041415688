import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import { getAuth, onAuthStateChanged } from "./firebase";
Vue.config.productionTip = false

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit('setUsuario', user) 
    store.dispatch('detectarUsuario')
  } 
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
  
});
