import Vue from 'vue';
import VueRouter from 'vue-router';
import { getAuth } from "../firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/Dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Presupuesto/Nuevo',
    name: 'pre_nuevo',
    component: () => import('../views/Presupuesto.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Clientes/Nuevo',
    name: 'new_clients',
    component: () => import('../components/Clientes/index.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Clientes/Listado',
    name: 'clients',
    component: () => import('../components/Clientes/Listado.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Clientes',
    name: 'clients',
    component: () => import('../components/Clientes/Detalles.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/busca',
    name: 'clientfs',
    component: () => import('../components/conver.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Presupuesto/Listado',
    name: 'get_presupuestos',
    component: () => import('../components/Presupuesto/Todos.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Cotizacion',
    name: 'cotizacion',
    component: () => import('../components/Presupuesto/Cotizacion.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Inventario',
    name: 'inventario',
    component: () => import('../components/Inventario/index.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Inventario/Listado',
    name: 'inventario_listado',
    component: () => import('../components/Inventario/Todos.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Guardados',
    name: 'Guardados',
    component: () => import('../components/Presupuesto/Editar.vue'),
    meta: { requireAuth: true }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requireAuth);
  const user = getAuth().currentUser;

  if (requiresAuth && !user) {
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router;
