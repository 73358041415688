<template>
  <v-app>
    <toolbar></toolbar>
    <v-main style="background: rgba(0,23,74,0.1);padding-bottom: 250px;">
      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
* {
  font-family: "Lato", sans-serif;
}
</style>

<script>
import { mapState } from 'vuex';
import toolbar from './components/toolbar.vue';
export default {
  name: 'App',
  components:{
    toolbar
  },

  data: () => ({
    //
  }),
  computed:{
    ...mapState(['usuario'])
  }
};
</script>
