import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { getAuth } from "../firebase";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario:null
  },
  getters: {
  },
  mutations: {
    setUsuario(state, e) { 
      state.usuario = e; 
    },
  },
  actions: {
    signOut({commit}){
      setTimeout(function() {
        commit('setUsuario', null)
        getAuth().signOut()
        window.open("/", "_self")
      }, 3000);
    },
    async detectarUsuario({commit}){
      try {
        const auth = getAuth();
        const usuario = auth.currentUser
      await axios.post('https://app.tecnigashp.com/php/login.php', {
        uid: usuario.uid
      }).then(res=>{
        commit('setUsuario', res.data[0])
      })
      } catch (error) {
          console.log(error)
      }
    },
  },
  modules: {
  }
})
